<template>
  <div :class="!isApp ? 'appTop' : ''">
    <div class="list">
      <div class="item" v-for="(item, index) in list" :key="index" @click="toUrl(item.link_url)">
        <div class="itemTitle">
          <div>合成时间：{{ getTime(item.start_time) }}-{{ getTime(item.end_time) }}</div>
          <div v-if="item.status == 1" class="status waitStatus">待合成</div>
          <div v-if="item.status == 2" class="status statusing">进行中</div>
          <div v-if="item.status == 3" class="status statusEnd">已结束</div>
        </div>
        <div class="itemImg">
          <img :src="item.image_url" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import { nftUtils } from '@/utils';
import moment from 'moment';
import { useRoute } from 'vue-router';
import { getmergelist } from '@/service/compound';

export default {
  name: 'compoundList',
  setup() {
    const route = useRoute();
    const state = reactive({
      isApp: nftUtils.isInApp(),
      list: []
    });

    onMounted(() => {
      getList();
    });

    const toUrl = link_url => {
      window.location.href = link_url;
    };

    const getList = () => {
      getmergelist({}).then(res => {
        console.log(res);
        state.list = res.data;
      });
    };

    // 获取格式时间
    const getTime = (time, format = 'YYYY-MM-DD HH:mm') => {
      return moment(time).format(format);
    };

    return {
      ...toRefs(state),
      getTime,
      toUrl
    };
  }
};
</script>

<style lang="scss" scoped>
.appTop {
  padding-top: 50px;
}
.list {
  padding: 0 16px;
  .item {
    margin-bottom: 16px;
    .itemTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #483f60;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      line-height: 32px;
      padding: 0 6px;

      .status {
        width: 49px;
        height: 20px;
        line-height: 20px;
        border-radius: 14px;
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        color: #1c172a;
      }
      .waitStatus {
        background: linear-gradient(132deg, #fff0bc 0%, #ffd057 100%);
      }

      .statusing {
        background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
      }

      .statusEnd {
        background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
        opacity: 0.6;
      }
    }

    .itemImg {
      img {
        width: 100%;
      }
    }
  }
}
</style>
